<template>
    <footer>
        <div class="footer-banner-container">
            <div class="container">
                <div class="footer-top">
                    <img fetchpriority="low" class="logo lazyload" :src="`${uploads}/assets/img/home/preologo-white.svg`" alt="preonow" />
                    <img fetchpriority="low" class="image lazyload" :src="`${uploads}/assets/img/home/footer-img.svg`" alt="footer image" />
                </div>
            </div>
        </div>
        <div class="container">
            <section class="menu">
                <div
                    class="ul-container"
                    v-for="(item, i) in menuData"
                    :key="`category-${i}`"
                    v-on:click="activeCategory = activeCategory === i ? '' : i"
                >
                    <ul>
                        <img v-if="isMobile" :class="activeCategory === i && 'menu-open'" :src="`${uploads}/assets/img/arrow-new.svg`" />
                        <h1>{{ item.label }}</h1>

                        <div v-if="!isMobile || (isMobile && activeCategory === i)" style="margin-top: 10px">
                            <li v-for="(subItem, i) in item.children" :key="`sub-${i}`">
                                <router-link v-if="subItem.route" :to="{ path: subItem.route }" :target="getTarget(subItem.route)">
                                    {{ subItem.label }}
                                </router-link>
                                <a v-else class="btn-link" :href="`${legalUri}/${subItem.aLink}`" target="_blank">{{ subItem.label }}</a>
                            </li>
                        </div>
                    </ul>
                </div>

                <div>
                    <ul v-if="!isMobile">
                        <h1>СОЦИАЛНИ МРЕЖИ</h1>
                    </ul>

                    <div class="social-media">
                        <img
                            fetchpriority="low"
                            class="logo"
                            :src="`${uploads}/assets/img/home/facebook.svg`"
                            alt="facebook"
                            @click="openLink('facebook')"
                        />
                        <img
                            fetchpriority="low"
                            class="logo"
                            :src="`${uploads}/assets/img/home/instagram.svg`"
                            alt="instagram"
                            @click="openLink('instagram')"
                        />
                        <img
                            fetchpriority="low"
                            class="logo"
                            :src="`${uploads}/assets/img/home/youtube.svg`"
                            alt="youtube"
                            @click="openLink('youtube')"
                        />
                    </div>
                </div>
            </section>

            <section class="text">
                <hr />
                <p class="p--med">
                    С предплащането на поръчка на стока или услуга в preonow.bg, Вие ги получавате на преференциални цени.
                    <br /><br />
                    Платформата обслужва само територията на България. Начин на ценообразуване: Задрасканата цена е Средната продажна цена
                    на стоката/услугата в други обекти сайтове на търговеца. Незадрасканата цена е Продажната цена на стоката/услугата,
                    която Вие пазарувате в платформата на preonow.bg.
                </p>

                <img class="efr--img lazyload" v-if="!isMobileOpen" :data-src="`${uploads}/assets/img/logo-efr.webp`" alt="ЕФР" />
                <img
                    class="fnf--img lazyload"
                    v-if="!isMobileOpen"
                    :data-src="`${uploads}/assets/img/logo-fnf.webp`"
                    alt="Фонд на фондовете"
                />
                <img
                    class="innovations--img lazyload"
                    v-if="!isMobileOpen"
                    :data-src="`${uploads}/assets/img/logo-innovations.webp`"
                    alt="Иновации и конкурентноспособност"
                />
                <img class="fibank--img lazyload" v-if="!isMobileOpen" :data-src="`${uploads}/assets/img/fibank.webp`" alt="Фибанк" />
                <img class="iris--img lazyload" v-if="!isMobileOpen" :data-src="`${uploads}/assets/img/iris.webp`" alt="IRIS" />
                <img class="bea--img lazyload" v-if="!isMobileOpen" :data-src="`${uploads}/assets/img/BEA-logo-member.webp`" alt="BEA" />
                <img
                    class="ft_bg--img lazyload"
                    v-if="!isMobileOpen"
                    :data-src="`${uploads}/assets/img/FT_logo.webp`"
                    alt="Fintech Bulgaria"
                />
                <hr />
                <div class="policy-img-wrapper">
                    <img v-if="!isMobileOpen" :data-src="`${uploads}/assets/img/payments.svg`" class="lazyload" alt="Preonow" />
                    <img v-else :data-src="`${uploads}/assets/img/payments-m.svg`" alt="Preonow" class="lazyload" />
                </div>
                <br />
            </section>
        </div>
        <section class="all-rights-reserved">
            <p>{{ date }} - Всички права запазени, Прио Нау ООД</p>
        </section>
    </footer>
</template>

<script>
    import { mapGetters } from "vuex";
    import utils from "@/common/utils";
    import 'lazysizes';

    export default {
        props: ['isMobileOpen'],
        components: {},
        data () {
            return {
                date: new Date().getFullYear(),
                isMobile: false,
                activeCategory: 0,
                menuData: [
                    {
                        'label': 'ЗА НАС',
                        'children': [
                            {
                                'label': 'Мисия/Визия',
                                'route': '/missionVision'
                            }, {
                                'label': 'Блог',
                                'route': '/blog/'
                            }, {
                                'label': 'Свържете се с нас',
                                'route': '/contacts'
                            }, {
                                'label': 'Станете търговец',
                                'route': '/becomeSeller'
                            }],
                    },
                    {
                        'label': 'ОБЩИ УСЛОВИЯ',
                        'children': [
                            {
                                'label': 'Общи условия',
                                // 'route': '/generalTerms',
                                'aLink': '/Obshti-uslovia.pdf'
                            }, {
                                'label': 'Бисквитки',
                                'route': '/cookies'
                            }, {
                                'label': 'Политика за поверителност',
                                'route': '/privacyPolicy'
                            }, {
                                'label': 'Условия за отказ',
                                'route': '/cancellationTerms'
                            }, {
                                'label': 'Формуляр за отказ',
                                'aLink': `/Cancellation_right.pdf`
                            }
                        ]
                    },
                    {
                        'label': 'ДОСТАВКА',
                        'children': [{
                            'label': 'Доставка на поръчки',
                            'route': '/contacts?scr=delivery'
                        }, {
                            'label': 'Как да направя поръчка',
                            'route': '/contacts?scr=make-order&toggle=q22'
                        }, {
                            'label': 'Често задавани въпроси',
                            'route': '/contacts?scr=faq'
                        }],
                    }
                ]
            };
        },
        computed:
        {
            ...mapGetters(['currentUser']),
            staticUri () {
                return process.env.VUE_APP_STATIC_URI;
            },
            uploads () {
                return `${process.env.VUE_APP_UPLOADS_URI}`;
            },
            legalUri () {
                return utils.legalUri();
            },
            getTarget () {
                // workaround to open the blog in new page
                return function (route) {
                    return route.includes('/blog/') ? '_blank' : '_self';
                };
            }
        },
        methods: {
            isMobileMode () {
                if (window.innerWidth <= 550) {
                    this.isMobile = true;
                } else {
                    this.isMobile = false;
                }
            },
            openLink (site) {
                let url = '';
                switch (site) {
                    case 'facebook':
                        url = 'https://www.facebook.com/Preonow.bg'
                        break;
                    case 'instagram':
                        url = 'https://www.instagram.com/preonowbg/'
                        break;
                    case 'youtube':
                        url = 'https://www.youtube.com/@preonow4981'
                        break;
                }

                return window.open(url, "_blank");
            },
        },
        mounted () {
            this.isMobileMode();
            window.addEventListener('resize', this.isMobileMode);
        },
        destroyed () {
            window.removeEventListener('resize', this.isMobileMode);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/scss/base.scss';

    footer {
        background-color: #fff;
        margin-top: 50px;
    }
    .footer-banner-container {
        background: linear-gradient(86deg, rgba(53, 8, 135, 1) 15%, rgba(177, 0, 178, 1) 50%, rgba(53, 8, 135, 1) 85%);
        overflow: hidden;

        .footer-top {
            height: 100px;
            display: flex;
            position: relative;
            align-items: center;
            justify-content: space-between;
            width: 98%;

            .logo {
                height: 50px;
                display: block;
                margin: auto 0;
            }

            .image {
                display: block;
                margin-top: auto;
                margin-right: -100px;
            }
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        width: 98%;
        margin-top: 20px;
        position: relative;

        h1 {
            color: #666666;
            font-size: 16px;
            padding: 10px 0;
        }

        ul {
            padding: 0;
            list-style: none;
        }

        a {
            color: $lightGrey;
        }

        .social-media {
            display: flex;
            justify-content: space-between;
            img {
                height: 25px;
                cursor: url('../assets/img/cursor.png'), auto;
            }
        }
    }

    .text {
        text-align: center;
        padding: 1rem;
        color: $lightGrey;
        width: 90%;
        margin-left: 5%;

        p {
            font-size: $px12;

            &.p--med {
                margin: 10px 0;
                font-size: 13px;
            }
        }

        hr {
            background-color: #ffffff;
            border: none;
            height: 1px;
            opacity: 0.5;
            margin: 0.5rem auto;
            width: 60%;
        }
        .policy-img-wrapper {
            margin-top: 1.3%;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                display: inline-block;
                height: 48px;
                max-width: 75vw;
                width: 350px;
            }
        }
        img {
            //width: 60px;
            height: 40px;
            margin: 20px;
            &.efr--img {
                margin: 5px 0 0 12px;
            }
            &.fnf--img {
                margin: 5px 0 0 12px;
            }
            &.fibank--img {
                margin: 5px 0 0 12px;
            }
            &.iris--img {
                margin: 5px 0 0 12px;
            }
            &.bea--img {
                margin: 5px 0 0 12px;
            }
            &.innovations--img {
                margin: 5px 0 0 12px;
            }
            &.ft_bg--img {
                margin: 5px 0 0 12px;
            }
        }
    }
    .all-rights-reserved {
        width: 100%;
        background-color: #350887;
        height: 50px;
        color: white;
        text-align: center;
        line-height: 50px;
    }

    @media all and (max-width: $standard) {
        .footer-banner-container .footer-top {
            height: 70px;

            .image {
                width: 280px;
                margin-right: -80px;
            }
        }
        .menu {
            margin-top: 0;

            .social-media {
                justify-content: center;

                img {
                    margin: 0 10px;
                }
            }

            ul {
                margin: 10px 0;
            }
        }
        .text {
            p.p--med {
                margin: 5px 0;
                font-size: 11px;
            }

            .policy-img-wrapper img {
                height: 35px;
            }

            img {
                height: 35px;
            }
        }
    }

    @media all and (max-width: $sm) {
        .menu {
            h1 {
                font-size: 14px;
            }

            a {
                font-size: 12px;
            }

            .social-media img {
                height: 18px;
            }
        }
    }

    @media all and (max-width: 550px) {
        .menu {
            font-family: 'Comfortaa', cursive;
            font-family: 'Roboto', sans-serif;
            display: block;

            a {
                font-size: 14px;
            }
            .ul-container {
                border: 2px solid #350887;
                border-radius: 15px;
                margin-bottom: 10px;
                padding-left: 20px;

                ul {
                    h1 {
                        color: #350887;
                        padding: 5px 0;
                    }
                    li {
                        padding-bottom: 10px;
                    }
                    img {
                        margin-top: 13px;
                        transform: translate(0, -50%) rotate(180deg) scale(0.3);
                        position: absolute;
                        right: 0;
                        filter: brightness(0) saturate(100%) invert(8%) sepia(71%) saturate(6032%) hue-rotate(263deg) brightness(89%)
                            contrast(110%);
                    }
                    .menu-open {
                        transform: translate(0, -50%) rotate(270deg) scale(0.3);
                    }
                }
            }
            .social-media {
                margin-top: 30px;
                .logo {
                    filter: brightness(0) saturate(100%) invert(8%) sepia(71%) saturate(6032%) hue-rotate(263deg) brightness(89%) contrast(110%);
                    height: 24px;
                }
            }
        }
        .text {
            width: 100%;
            margin-left: 0;
            padding: 0;
        }
        .all-rights-reserved {
            font-size: 12px;
        }
    }
</style>
